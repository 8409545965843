.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;
}

.link-button:hover, .link-button:focus {
    text-decoration: none;
}

.divTab {
    position: relative;
}

.btnFecharTab {
    position: absolute;
    top: -2px;
    right: 5px;
}

.aside-heigth-correction {
    height: 100vh !important;
}

.pointer {
    cursor: pointer;
}

.overlay-custom {
    border-radius: .25rem;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100vh) !important;
    z-index: 2000 !important;
    position: fixed !important;
}

.overlay-suspense {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100vh) !important;
    width: calc(100vw) !important;
    z-index: 2000 !important;
    position: fixed !important;
}